import React from 'react';
import { graphql } from 'gatsby';

import Heading from '../components/atoms/Heading';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PortfolioItem, { PortfolioData } from '../components/molecules/PortfolioItem';

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              mobile: childImageSharp {
                fixed(width: 200, height: 433) {
                  ...GatsbyImageSharpFixed
                }
              }
              web: childImageSharp {
                fixed(width: 400, height: 255) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            description
            responsibilities
            date
            type
          }
        }
      }
    }
  }
`;

const PortfolioPage = ({ data }: any) => (
  <Layout>
    <SEO title="Portfolio" />
    <Heading mb={16}>Stuff I&apos;ve Made</Heading>
    {data.allMarkdownRemark.edges.map((post: PortfolioData) => (
      <PortfolioItem
        key={post.node.frontmatter.title}
        title={post.node.frontmatter.title}
        date={post.node.frontmatter.date}
        featuredImage={post.node.frontmatter.featuredImage}
        responsibilities={post.node.frontmatter.responsibilities}
        description={post.node.frontmatter.description}
        slug={post.node.fields.slug}
        type={post.node.frontmatter.type}
      />
    ))}
  </Layout>
);

export default PortfolioPage;
